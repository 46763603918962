import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BehaviorSubject, delay } from 'rxjs';
import { LoaderService } from './loader.service';

@Component({
  imports: [CommonModule],
  standalone: true,
  selector: 'rp-loader',
  templateUrl: './loader.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent implements OnInit {
  loading$ = new BehaviorSubject<boolean>(false);
  progressBarPercentage = 0;

  constructor(private loaderService: LoaderService, private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.loaderService.progressBarPercentage$.subscribe((resp) => {
      if (resp > 0) {
        this.progressBarPercentage = resp
        this.cd.markForCheck();
      }
    })
    this.loaderService.isLoading$.pipe(delay(350)).subscribe((value) => {
      this.loading$.next(value);
    });
  }
}
