import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as CallHubActions from '@erm/web/call-center/call/data-access';
import {
  CallCenterSettings,
  CallHubService,
  CallHubState,
  isBusySelector,
  isInCommingCallSelector,
  LeadCriteria,
  LeadInfo,
  leadInfoSelector,
} from '@erm/web/call-center/call/data-access';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { skip } from 'rxjs';

declare const Twilio: any;
@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'call-hub',
  templateUrl: './call-hub.component.html',
  styleUrls: ['./call-hub.component.scss'],
})
export class CallHubComponent implements OnInit {
  constructor(
    private store: Store<CallHubState>,
    private actionListener$: ActionsSubject,
    public callHubService: CallHubService,
    private router: Router
  ) {
    this.store.dispatch(CallHubActions.getConfig());
  }

  leadInfo!: LeadInfo;
  isBusy!: boolean;
  isInComming!: boolean;

  ngOnInit(): void {
    this.actionListener$.pipe(skip(1)).subscribe((action: any) => {
      if (action.type === CallHubActions.GET_SETTINGS_SUCCESS) {
        const setting: CallCenterSettings = action.settings;
        if (setting && setting.accessToken) {
          this.twilioSetup(setting.accessToken);
          this.stateInit();
        }
      }
    });
  }

  private twilioSetup(accessToken: string) {
    Twilio.Device.setup(accessToken, { debug: true });

    Twilio.Device.on('ready', () => {
      console.log("[Twilio.Device.on('ready')]");
    });

    Twilio.Device.on('error', (error: any) => {
      console.log("[Twilio.Device.on('error')] " + error.message);
    });

    Twilio.Device.on('connect', (conn: any) => {
      if (conn.status() === 'open') {
        this.store.dispatch(
          CallHubActions.setIsBusyState({
            state: true,
          })
        );
      }
      console.log(
        "[Twilio.Device.on('connect')] CallSid " + conn.parameters.CallSid
      );
    });

    Twilio.Device.on('cancel', () => {
      console.log("[Twilio.Device.on('cancel')]");
      this.store.dispatch(
        CallHubActions.setIsBusyState({
          state: false,
        })
      );
      this.store.dispatch(
        CallHubActions.setIsInCommingState({
          state: false,
        })
      );
    });

    Twilio.Device.on('incoming', (conn: any) => {
      console.log("[Twilio.Device.on('incoming')] ");
      this.callHubService.incomingConn = conn;
      const criteria: LeadCriteria = {
        phone: conn.parameters.From,
      };
      this.store.dispatch(
        CallHubActions.getLeadInfo({
          criteria: criteria,
        })
      );

      this.store.dispatch(
        CallHubActions.setIsInCommingState({
          state: false,
        })
      );
    });

    Twilio.Device.on('disconnect', (conn: any) => {
      console.log("[Twilio.Device.on('disconnect')]");
      this.store.dispatch(
        CallHubActions.setIsBusyState({
          state: false,
        })
      );
      this.store.dispatch(
        CallHubActions.setIsInCommingState({
          state: false,
        })
      );
    });
  }

  answerCall() {
    if (this.callHubService.incomingConn.status() === 'closed') {
      this.store.dispatch(
        CallHubActions.setIsBusyState({
          state: false,
        })
      );
      alert('This call has been answered.');
      return;
    }

    this.store.dispatch(
      CallHubActions.setIsInCommingState({
        state: true,
      })
    );
    this.callHubService.incomingConn.accept();
  }

  cancelCall() {
    this.store.dispatch(
      CallHubActions.setIsBusyState({
        state: false,
      })
    );

    if (this.callHubService.incomingConn.status() === 'open')
      this.callHubService.incomingConn.disconnect();
    else this.callHubService.incomingConn.ignore();
  }

  private stateInit() {
    this.store
      .pipe(select(leadInfoSelector))
      .subscribe((info) => (this.leadInfo = { ...info }));
    this.store
      .pipe(select(isBusySelector))
      .subscribe((state) => (this.isBusy = state));
    this.store
      .pipe(select(isInCommingCallSelector))
      .subscribe((state) => (this.isInComming = state));
  }

  getLeadName() {
    if (
      this.leadInfo &&
      (this.leadInfo?.lastName || this.leadInfo?.firstName)
    ) {
      return (this.leadInfo?.lastName ?? '') + (this.leadInfo?.firstName ?? '');
    }
    return 'Unknown';
  }

  goToLeadDetail() {
    if (
      this.leadInfo &&
      (this.leadInfo?.lastName || this.leadInfo?.firstName)
    ) {
      this.router.navigate([`/leads/${this.leadInfo.leadId}`]);
    }
  }
}
