import { importProvidersFrom } from '@angular/core';
import { Route } from '@angular/router';
import { AuthGuardService } from '@erm/web/auth/data-access';
import { CustomerRegistrationComponent } from '@erm/web/auth/feature/customer-registration';
import { RegisterSuccessComponent } from '@erm/web/auth/ui/register-success';
import {
  CALL_HUB_STORE,
  CallHubEffects,
  CallHubReducer,
} from '@erm/web/call-center/call/data-access';
import { pinReducer } from '@erm/web/cms/e-sign/data-access';
import { ESignDrawComponent } from '@erm/web/cms/e-sign/feature/e-sign-draw';
import { ESignLoginComponent } from '@erm/web/cms/e-sign/feature/e-sign-login';
import { ESignSignatureComponent } from '@erm/web/cms/e-sign/feature/e-sign-signature';
import { ResetPinComponent } from '@erm/web/cms/e-sign/feature/reset-pin';
import {
  ORG_SETTINGS_STORE,
  OrgSettingsEffect,
  orgSettingsReducer,
} from '@erm/web/common/org-settings/data-access';
import { AuthLayoutComponent } from '@erm/web/shell/ui/auth-layout';
import { ContentLayoutComponent } from '@erm/web/shell/ui/content-layout';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SigningSuccessComponent } from '@erm/web/cms/e-sign/ui/signing-success';

export const WebShellRoutes: Route[] = [
  {
    path: '',
    redirectTo: '/auth/app-loading',
    pathMatch: 'full',
  },
  {
    path: 'login',
    redirectTo: '/auth/login',
    pathMatch: 'full',
  },
  {
    path: 'logout',
    redirectTo: '/auth/logout',
    pathMatch: 'full',
  },
  {
    path: 'unauthorized',
    redirectTo: '/auth/unauthorized',
    pathMatch: 'full',
  },
  { path: 'customer-registration', component: CustomerRegistrationComponent },
  { path: 'register-success', component: RegisterSuccessComponent },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('@erm/web/auth/feature/shell').then((m) => m.AuthShellRoutes),
  },
  {
    path: 'leads',
    component: ContentLayoutComponent,
    loadChildren: () =>
      import('@erm/web/lead/feature/shell').then((m) => m.LeadShellRoutes),
    data: { module: 'lead', funcVal: '0' },
    canActivate: [AuthGuardService],
    providers: [
      AuthGuardService,
      importProvidersFrom([
        StoreModule.forFeature(CALL_HUB_STORE, CallHubReducer),
        EffectsModule.forFeature([CallHubEffects]),
        StoreModule.forFeature(ORG_SETTINGS_STORE, orgSettingsReducer),
        EffectsModule.forFeature([OrgSettingsEffect]),
      ]),
    ],
  },
  {
    path: 'listings',
    component: ContentLayoutComponent,
    loadChildren: () =>
      import('@erm/web/listing/feature/shell').then(
        (m) => m.ListingShellRoutes
      ),
    data: { module: 'property', funcVal: '0' },
    canActivate: [AuthGuardService],
    providers: [
      AuthGuardService,
      importProvidersFrom([
        StoreModule.forFeature(ORG_SETTINGS_STORE, orgSettingsReducer),
        EffectsModule.forFeature([OrgSettingsEffect]),
      ]),
    ],
  },
  {
    path: 'settings',
    component: ContentLayoutComponent,
    loadChildren: () =>
      import('@erm/web/setting/feature/shell').then(
        (m) => m.SettingShellRoutes
      ),
    providers: [
      AuthGuardService,
      importProvidersFrom([
        StoreModule.forFeature(CALL_HUB_STORE, CallHubReducer),
        EffectsModule.forFeature([CallHubEffects]),
        StoreModule.forFeature(ORG_SETTINGS_STORE, orgSettingsReducer),
        EffectsModule.forFeature([OrgSettingsEffect]),
      ]),
    ],
    // data: { module: 'setting', funcVal: 0 },
    // canActivate: [AuthGuardService],
  },
  {
    path: 'management',
    component: ContentLayoutComponent,
    loadChildren: () =>
      import('@erm/web/management/feature/shell').then(
        (m) => m.ManagementShellRoutes
      ),
    data: { module: 'property', funcVal: '0' },
    canActivate: [AuthGuardService],
    providers: [
      AuthGuardService,
      importProvidersFrom([
        StoreModule.forFeature(ORG_SETTINGS_STORE, orgSettingsReducer),
        EffectsModule.forFeature([OrgSettingsEffect]),
      ]),
    ],
  },
  {
    path: 'marketing',
    component: ContentLayoutComponent,
    providers: [
      AuthGuardService,
      importProvidersFrom([
        StoreModule.forFeature(ORG_SETTINGS_STORE, orgSettingsReducer),
        EffectsModule.forFeature([OrgSettingsEffect]),
      ]),
    ],
    loadChildren: () =>
      import('@erm/web/marketing/feature/shell').then(
        (m) => m.MarketingShellRoutes
      ),
  },
  {
    path: 'cms',
    component: ContentLayoutComponent,
    loadChildren: () =>
      import('@erm/web/cms/feature/shell').then((m) => m.CmsShellRoutes),
    providers: [
      AuthGuardService,
      importProvidersFrom([
        StoreModule.forFeature(ORG_SETTINGS_STORE, orgSettingsReducer),
        EffectsModule.forFeature([OrgSettingsEffect]),
      ]),
    ],
    data: { module: 'cms', funcVal: '0' },
    canActivate: [AuthGuardService],
  },
  //{
  //   path: 'app-settings',
  //   component: ContentLayoutComponent,
  //   loadChildren: () =>
  //     import('@erm/web/app-settings/feature/shell').then(
  //       (m) => m.AppSettingsShellModule
  //     ),
  // },
  {
    path: 'cart',
    component: ContentLayoutComponent,
    loadChildren: () =>
      import('@erm/web/cart/feature/shell').then((m) => m.CartShellRoutes),
    data: { module: 'cart' },
    providers: [
      AuthGuardService,
      importProvidersFrom([
        StoreModule.forFeature(ORG_SETTINGS_STORE, orgSettingsReducer),
        EffectsModule.forFeature([OrgSettingsEffect]),
      ]),
    ],
    //canActivate: [AuthGuardService],
  },
  {
    path: 'mobile',
    // component: MobileMailWizardComponent,
    loadChildren: () =>
      import('@erm/web/mobile/feature/shell').then((m) => m.MobileShellRoutes),
    providers: [
      AuthGuardService,
      importProvidersFrom([
        StoreModule.forFeature(ORG_SETTINGS_STORE, orgSettingsReducer),
        EffectsModule.forFeature([OrgSettingsEffect]),
      ]),
    ],
  },
  {
    path: 'rent-applications',
    component: ContentLayoutComponent,
    providers: [
      AuthGuardService,
      importProvidersFrom([
        StoreModule.forFeature(ORG_SETTINGS_STORE, orgSettingsReducer),
        EffectsModule.forFeature([OrgSettingsEffect]),
      ]),
    ],
    loadChildren: () =>
      import('@erm/web/rent-application/feature/shell').then(
        (m) => m.RentApplicationShellRoutes
      ),
  },
  {
    path: 'call-center',
    component: ContentLayoutComponent,
    providers: [
      AuthGuardService,
      importProvidersFrom([
        StoreModule.forFeature(ORG_SETTINGS_STORE, orgSettingsReducer),
        EffectsModule.forFeature([OrgSettingsEffect]),
      ]),
    ],
    loadChildren: () =>
      import('@erm/web/call-center/feature/shell').then(
        (m) => m.CallCenterShellRoutes
      ),
  },
  {
    path: 'flow',
    component: ContentLayoutComponent,
    providers: [
      AuthGuardService,
      importProvidersFrom([
        StoreModule.forFeature(ORG_SETTINGS_STORE, orgSettingsReducer),
        EffectsModule.forFeature([OrgSettingsEffect]),
      ]),
    ],
    loadChildren: () =>
      import('@erm/web/workflow/feature/shell').then(
        (m) => m.WorkflowShellRoutes
      ),
  },
  {
    path: 'billing',
    component: ContentLayoutComponent,
    providers: [
      AuthGuardService,
      importProvidersFrom([
        StoreModule.forFeature(ORG_SETTINGS_STORE, orgSettingsReducer),
        EffectsModule.forFeature([OrgSettingsEffect]),
      ]),
    ],
    loadChildren: () =>
      import('@erm/web/billing/feature/shell').then(
        (m) => m.BillingShellRoutes
      ),
  },
  { path: '**', redirectTo: '/auth/not-found', pathMatch: 'full' },
];
