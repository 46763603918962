import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { Observable, finalize } from 'rxjs';
import { LoaderService } from './loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<Record<string, string>>[] = [];

  constructor(private loaderService: LoaderService) {}

  removeRequest(req: HttpRequest<Record<string, string>>) {
    const i = this.requests.indexOf(req);
    this.loaderService.finishRequest()
    if (i >= 0) {
      this.requests.splice(i, 1);// decrease
    }
    this.loaderService.toggleLoading(this.requests.length > 0);
  }

  intercept(
    req: HttpRequest<Record<string, string>>,
    next: HttpHandler
  ): Observable<HttpEvent<Record<string, string>>> {
    this.requests.push(req); // increase
    this.loaderService.toggleLoading(true);
    this.loaderService.newRequest();
    return next.handle(req).pipe(
      finalize(() => {
        this.removeRequest(req);
      })
    );
  }
}

export const loaderInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: LoaderInterceptor,
  multi: true,
};
