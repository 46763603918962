import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CallHubComponent } from '@erm//web/call-center/call/feature/hub';
import { LoaderComponent } from '@erm/web/shared/ui/loader';
import { NavComponent } from '@erm/web/shell/ui/nav';
import { NewLeadsComponent } from '@erm/web/shell/ui/new-leads';

@Component({
  imports: [
    CommonModule,
    RouterModule,
    NavComponent,
    LoaderComponent,
    CallHubComponent,
    NewLeadsComponent,
  ],
  standalone: true,
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
})
export class ContentLayoutComponent {
  constructor() {}
}
