import { AppConfig } from '@erm/web/shared/app-config';

export const environment: AppConfig = {
  production: true,
  baseUrl: 'http://portal-test.erm1.com',
  portalApiBaseUrl: 'https://api-test.erm1.com',
  portalGrpcUrl: 'https://grpc.erm1.com',
  portalTokenKey: 'portal_token',
  portalSsoTokenKey: 'portal_sso_token',
  menuAppName: 'Portal',
  followUpFilterTokenKey: 'follow_up_filter_token_key',
  crossTab: 'cross_tab',
  exp: 'exp',
  lastApiCallTime: 'last_api_call_time',
};
