<div
  class="fixed bg-white bottom-0 right-0 w-1/5 shadow-2xl border-2 z-50"
  *ngIf="isShow$ | async"
>
  <div class="relative">
    <div class="flex justify-between bg-blue-500" (click)="toggle()">
      <h2 class="ml-1 my-auto text-white font-bold">
        <span>{{
          (newUserLeads$ | async)?.length + ' / ' + (links$ | async)?.totalItems
        }}</span>
        New Leads
        <svg
          (click)="refreshData()"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          class="cursor-pointer tw-icon-5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
      </h2>
      <div class="flex mr-1">
        <svg
          [ngClass]="(isToggle$ | async) ? 'rotate-180' : 'rotate-0'"
          class="h-6 text-white duration-200 delay-100 transform-gpu cursor-pointer"
          fill="none"
          stroke="currentColor"
          stroke-width="1.5"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M4.5 15.75l7.5-7.5 7.5 7.5"
          ></path>
        </svg>
        <svg
          (click)="checkAllNewUserLeads($event)"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
          class="tw-icon-6 text-white cursor-pointer"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </div>
    </div>
    <div
      [ngClass]="(isToggle$ | async) ? 'h-64' : 'h-0'"
      class="ease-linear duration-300 overflow-y-auto overflow-x-hidden"
    >
      <erm-table
        [links]="links$"
        [data]="newUserLeads$"
        [pagingMode]="'infinity'"
        (onPageIndexChanged)="onPageIndexChanged($event)"
      >
        <ng-container tbBody>
          <ng-template let-lead #bodyRef>
            <td>
              <a href="/leads/{{ lead.id }}" target="_blank">
                {{ lead.leadName?.trim() ? lead.leadName : '[No Name]' }}
              </a>
              <a class="block" (click)="showMessage(lead.id)">Message</a>
            </td>
            <td>
              <span>{{ lead.createdDate | date : 'MM/dd hh:mm a' }}</span>
              <br />
              <span>{{ lead?.source?.name }}</span>
            </td>
            <td class="relative">
              <a
                class="cursor-pointer"
                *ngIf="(leadPermissions$ | async)?.VIEW_All_USER"
              >
                <svg
                  class="tw-icon-4"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                  />
                </svg>
                <select
                  (change)="onEmployeeChange($event.target, lead)"
                  class="absolute top-0 left-3 opacity-0 z-10 capitalize cursor-pointer ng-pristine ng-valid ng-touched"
                >
                  <option
                    *ngFor="let employee of employees$ | async"
                    value="{{ employee.value }}"
                  >
                    {{ employee.name }}
                  </option>
                </select>
              </a>
              <a
                *ngIf="!(leadPermissions$ | async)?.VIEW_All_USER"
                (click)="assignToMe(lead)"
              >
                Assign To Me
              </a>
            </td>
          </ng-template>
        </ng-container>
      </erm-table>
    </div>
  </div>
</div>
