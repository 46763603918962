<div class="min-h-screen bg-gray-50 flex flex-col">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <h2
      class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900"
    >
      Customer Registration
    </h2>
  </div>
  <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <form [formGroup]="rf" (ngSubmit)="onSubmit()">
        <div class="grid grid-cols-2 gap-4">
          <div class="tw-form-group">
            <label for="firstName"> First Name </label>
            <div class="mt-1 rounded-md shadow-sm">
              <input
                id="firstName"
                type="text"
                formControlName="firstName"
                class="tw-form-control"
              />
            </div>
            <erm-form-error
              [message]="'Please enter first name.'"
              [targetControl]="rf.controls['firstName']"
            ></erm-form-error>
          </div>
          <div class="tw-form-group">
            <label for="lastName"> Last Name </label>
            <div class="mt-1 rounded-md shadow-sm">
              <input
                id="lastName"
                type="text"
                formControlName="lastName"
                class="tw-form-control"
              />
            </div>
            <erm-form-error
              [message]="'Please enter last name.'"
              [targetControl]="rf.controls['lastName']"
            ></erm-form-error>
          </div>
        </div>
        <div class="tw-form-group mt-1">
          <label for="email"> Email </label>
          <div class="mt-1 rounded-md shadow-sm">
            <input
              id="email"
              type="email"
              formControlName="email"
              class="tw-form-control"
            />
          </div>
          <erm-form-error
            [message]="'Please enter valid email address.'"
            [targetControl]="rf.controls['email']"
          ></erm-form-error>
        </div>
        <div class="tw-form-group mt-1">
          <label for="phone"> Phone Number </label>
          <div class="mt-1 rounded-md shadow-sm">
            <input
              id="phone"
              type="text"
              formControlName="phone"
              class="tw-form-control"
            />
          </div>
          <erm-form-error
            [message]="'Please enter phone number.'"
            [targetControl]="rf.controls['phone']"
          ></erm-form-error>
        </div>
        <div class="tw-form-group mt-1">
          <label for="password"> Password </label>
          <div class="mt-1 rounded-md shadow-sm relative">
            <input
              id="password"
              [type]="isShowPassword ? 'text' : 'password'"
              formControlName="password"
              class="tw-form-control pr-8"
            />
            <div
              class="absolute inset-y-0 right-2 flex items-center tw-text-primary cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="tw-icon-5"
                *ngIf="isShowPassword"
                (click)="isShowPassword = false"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="tw-icon-5"
                *ngIf="!isShowPassword"
                (click)="isShowPassword = true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                />
              </svg>
            </div>
          </div>
          <erm-form-error
            [message]="
              'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.'
            "
            [targetControl]="rf.controls['password']"
          ></erm-form-error>
        </div>
        <div class="mt-2">
          <span class="block w-full rounded-md shadow-sm">
            <button
              [disabled]="
                rf.invalid ||
                (processing$ | async) ||
                (registerSuccess$ | async)
              "
              type="submit"
              class="w-full tw-btn-primary"
            >
              <svg
                *ngIf="processing$ | async"
                class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <span *ngIf="!(processing$ | async)">Register</span>
            </button>
          </span>
        </div>
      </form>
    </div>
  </div>
</div>
