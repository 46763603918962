<div class="max-w-7xl mx-auto py-12 sm:px-6 lg:px-8">
  <div class="max-w-3xl mx-auto">
    <div class="bg-white shadow sm:rounded-lg">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">ERM System</h3>
        <div class="mt-2 max-w-xl text-sm text-gray-500">
          <p>
            You have successfully registered. Please check your email inbox,
            including the spam folder, for the verification email. If you do not
            receive the email please wait for a few minutes.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>