import { Component, HostListener } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { RenewTokenService } from '@erm/web/shared/data-access';
import { ToastComponent } from '@erm/web/shared/ui/toast';
// import { NotificationGrpcService } from '@erm/web/shell/ui/nav';

@Component({
  selector: 'rp-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterOutlet, ToastComponent],
  standalone: true,
})
export class AppComponent {
  constructor(private renewTokenService: RenewTokenService) {
    this.renewTokenService.crossTabListener();
    this.renewTokenService.scheduled();
  }
}
