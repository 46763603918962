import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  AuthService,
  RegisterCustomerAccountCommand,
} from '@erm/web/auth/data-access';
import { FormErrorComponent } from '@erm/web/shared/ui/form-error';
import { ToastService } from '@erm/web/shared/ui/toast';
import { BehaviorSubject } from 'rxjs';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'customer-registration',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormErrorComponent,
    RouterModule,
  ],
  templateUrl: './customer-registration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerRegistrationComponent {
  isShowPassword = false;
  registerSuccess$ = new BehaviorSubject<boolean>(false);
  passwordRegex =
    '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$';
  rf = new FormGroup({
    firstName: new FormControl<string>('', Validators.required),
    lastName: new FormControl<string>('', Validators.required),
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    phone: new FormControl<string>('', Validators.required),
    password: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(this.passwordRegex),
    ]),
  });
  processing$ = new BehaviorSubject<boolean>(false);
  constructor(
    private authService: AuthService,
    private toastService: ToastService,
    private router: Router
  ) {}

  onSubmit() {
    const postData: RegisterCustomerAccountCommand = <
      RegisterCustomerAccountCommand
    >{
      ...this.rf.value,
      timezone: (new Date().getTimezoneOffset() / 60) * -1,
    };
    this.processing$.next(true);
    this.authService.registerCustomer(postData).subscribe((resp) => {
      this.processing$.next(false);
      this.registerSuccess$.next(resp.data ?? false);
      if (resp.success) {
        this.toastService.success(
          'Registration',
          `An email has been sent to ${this.rf.value.email}.`
        );
        this.router.navigate(['register-success']);
      } else {
        this.toastService.error('Registration', resp.message);
      }
    });
  }
}
