import { CommonModule, DOCUMENT } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { APP_CONFIG } from '@erm/web/shared/app-config';
import {
  ApiService,
  CookieService,
  RenewTokenService,
  StorageService,
  authInterceptorProvider,
  catchErrorInterceptorProvider,
  tokenInterceptorProvider,
} from '@erm/web/shared/data-access';
import { loaderInterceptorProvider } from '@erm/web/shared/ui/loader';
import { OverlayService } from '@erm/web/shared/ui/modal-overlay';
import { WebShellRoutes } from '@erm/web/shell/feature';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { pinReducer } from '@erm/web/cms/e-sign/data-access';

if (environment.production) {
  enableProdMode();
}

const overrideEnv = async <T>(env: any): Promise<T> => {
  try {
    const response = await fetch('/portal/app-setting');
    const dynamicEnv: any = await response.json();
    if (dynamicEnv) {
      env.portalApiBaseUrl =
        dynamicEnv.portalApiBaseUrl || env.portalApiBaseUrl;
      env.portalTokenKey = dynamicEnv.portalTokenKey || env.portalTokenKey;
      env.portalSsoTokenKey =
        dynamicEnv.portalSsoTokenKey || env.portalSsoTokenKey;
      env.menuAppName = dynamicEnv.menuAppName || env.menuAppName;
      env.loginDate = dynamicEnv.loginDate || env.loginDate;
    }
  } catch (e) {
    /*Nothing*/
  }
  return env;
};

// main();
const main = async () => {
  overrideEnv(environment).then((dynamicEnv) => {
    bootstrapApplication(AppComponent, {
      providers: [
        importProvidersFrom(
          BrowserModule,
          BrowserAnimationsModule,
          CommonModule,
          HttpClientModule,
          // ModalOverlayModule,
          //      SharedDataAccessModule,
          // AppConfigModule
          //WebShellModule,
          RouterModule.forRoot(WebShellRoutes),
          StoreModule.forRoot({ router: routerReducer }),
          StoreModule.forRoot({
            pin: pinReducer,
          }),
          StoreRouterConnectingModule.forRoot(),
          EffectsModule.forRoot(),
          StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            autoPause: true, // Pauses recording actions and state changes when the extension window is not open
          })
        ),
        { provide: APP_CONFIG, useValue: dynamicEnv },
        { provide: Document, useExisting: DOCUMENT },
        { provide: ApiService, useClass: ApiService },
        { provide: CookieService, useClass: CookieService },
        { provide: StorageService, useClass: StorageService },
        { provide: OverlayService, useClass: OverlayService },
        { provide: RenewTokenService, useClass: RenewTokenService },
        loaderInterceptorProvider,
        tokenInterceptorProvider,
        authInterceptorProvider,
        catchErrorInterceptorProvider,
      ],
    }).catch((err) => console.error(err));
  });
};

main();
