import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import * as OrgSettingsActions from '@erm/web/common/org-settings/data-access';
import {
  LeadPermissions,
  OrgAuthUser,
  OrgSettingsState,
  leadConfigSelector,
  listingConfigSelector,
  orgAuthUserSelector,
} from '@erm/web/common/org-settings/data-access';
import { HtmlMessagesComponent } from '@erm/web/lead/dashboard/ui/html-messages';
import { LinksPaginator, OptionInfo } from '@erm/web/shared/data-access';
import { PhonePipe } from '@erm/web/shared/pipes';
import { ErmTableComponent } from '@erm/web/shared/ui/erm-table';
import { OverlayService } from '@erm/web/shared/ui/modal-overlay';
import { ToastService } from '@erm/web/shared/ui/toast';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { AssignEmployeeCommand } from './data-access/commands';
import { DashboardNewLeadItem, NewLeads } from './data-access/models';
import { NewLeadsService } from './data-access/services';

@Component({
  selector: 'new-user-leads',
  standalone: true,
  imports: [CommonModule, ErmTableComponent],
  templateUrl: './new-leads.component.html',
  providers: [PhonePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewLeadsComponent {
  newUserLeads$ = new BehaviorSubject<NewLeads[]>([]);
  employees$ = new BehaviorSubject<OptionInfo[]>([]);
  isToggle$ = new BehaviorSubject<boolean>(false);
  isShow$ = new BehaviorSubject<boolean>(false);
  links$ = new BehaviorSubject<LinksPaginator>({
    pageIndex: 0,
    pageSize: 10,
    totalItems: 0,
  });

  leadPermissions$ = new BehaviorSubject<LeadPermissions | undefined>(
    undefined
  );
  orgAuthUserSelector$ = new BehaviorSubject<OrgAuthUser>({});

  constructor(
    private service: NewLeadsService,
    private overlayService: OverlayService,
    private orgSettingsStore: Store<OrgSettingsState>,
    private phonePipe: PhonePipe,
    private toastService: ToastService
  ) {
    this.orgSettingsStore.dispatch(OrgSettingsActions.getLeadConfig());

    this.orgSettingsStore
      .pipe(select(listingConfigSelector))
      .subscribe((resp) => {
        if (resp && resp.employees) {
          this.employees$.next(resp.employees);
        }
      });

    this.orgSettingsStore.pipe(select(leadConfigSelector)).subscribe((resp) => {
      if (resp) {
        this.leadPermissions$.next(resp.permissions);
        if (resp.permissions?.VIEW_NEW_LEADS) {
          this.getNewLeads();
        }
      }
    });

    this.orgSettingsStore
      .pipe(select(orgAuthUserSelector))
      .subscribe((resp) => this.orgAuthUserSelector$.next(resp));
  }

  getNewLeads() {
    const { pageIndex, pageSize, totalPages, totalItems } = this.links$.value;
    let copyPageIndex = pageIndex;

    if (Math.ceil(totalItems / pageSize) < totalPages!) {
      copyPageIndex--;
    }

    this.service.getNewLeads(copyPageIndex, pageSize).subscribe((resp) => {
      if (resp && resp.data && resp.success && resp.links) {
        const newUserLeads = this.newUserLeads$.value;
        const dataResp = resp.data.filter(
          (item) => newUserLeads.findIndex((lead) => lead.id == item.id) < 0
        );
        this.newUserLeads$.next([...newUserLeads, ...dataResp]);
        this.links$.next(resp.links);
        this.isShow$.next(resp.data.length > 0);
      }
    });
  }

  public assignToMe(lead: DashboardNewLeadItem) {
    // this.onEmployeeChange(employeeId: )
    const employee = this.orgAuthUserSelector$.value;
    const confirmAction = confirm(
      `Do you want to assign: You for lead ${
        lead.leadName
      }, phone: ${this.phonePipe.transform(lead.phone)}?`
    );
    if (!confirmAction) return;
    this.assignEmployee(employee.id ?? -1, lead);
  }

  public onEmployeeChange(
    target: EventTarget | null,
    lead: DashboardNewLeadItem
  ) {
    const employeeId = +(target as HTMLSelectElement).value;

    const employee = this.employees$.value.find(
      (employee) => +employee.value == employeeId
    );
    if (employee && employeeId && lead.id) {
      const confirmAction = confirm(
        `Do you want to assign: \n${employee.name} for lead ${
          lead.leadName
        }, phone: ${this.phonePipe.transform(lead.phone)}?`
      );
      if (!confirmAction) return;
      this.assignEmployee(employeeId, lead);
    }
  }

  assignEmployee(employeeId: number, lead: DashboardNewLeadItem) {
    const optionAssign: AssignEmployeeCommand = {
      ownerId: employeeId,
      forceDuplicated: false,
      forceNoFollowUp: false,
    };

    this.service.assignEmployee(optionAssign, lead.id).subscribe((resp) => {
      if (resp.success) {
        this.toastService.success(
          'Successful',
          'Lead have assigned successfully!',
          3000
        );

        this.filterDataNewUserLeads(lead.id);
        this.getNewLeads();
      } else {
        switch (resp.code) {
          case 'EXISTED': {
            const confirmDuplicate = confirm(
              'Looks like the lead has been assigned,\n do you want to continue assign for this lead?'
            );
            if (!confirmDuplicate) return;

            optionAssign.forceDuplicated = true;
            this.service
              .assignEmployee(optionAssign, lead.id)
              .subscribe((resp) => {
                if (resp.success) {
                  this.filterDataNewUserLeads(lead.id);
                  this.getNewLeads();
                }
                this.toastService.saveCompleted(
                  resp.success,
                  'Lead',
                  resp.message
                );
              });
            break;
          }
          case 'WARNING': {
            const agree = confirm(
              'Looks like this action will have an impact,\n do you want to continue?'
            );
            if (!agree) return;

            optionAssign.forceNoFollowUp = true;
            this.service
              .assignEmployee(optionAssign, lead.id)
              .subscribe((resp) => {
                if (resp.success) {
                  if (resp.success) {
                    this.filterDataNewUserLeads(lead.id);
                    this.getNewLeads();
                  }
                }
              });
            break;
          }

          case 'NO_PERMISSION':
            this.toastService.error(
              'Failed',
              "You don't have permission to assign lead"
            );
            break;

          default:
            this.toastService.error(
              'Failed',
              'Have something wrong, please try again or contact to system Admin'
            );
            break;
        }
      }
    });
  }

  refreshData() {
    this.newUserLeads$.next([]);
    this.onPageIndexChanged(0);
  }

  checkAllNewUserLeads(event: Event) {
    event.stopPropagation();
    this.service.checkAllNewLeads().subscribe((resp) => {
      if (resp.success) {
        this.isShow$.next(false);
      }
    });
  }

  filterDataNewUserLeads(leadId: number) {
    this.newUserLeads$.next(
      this.newUserLeads$.value.filter((val) => val.id !== leadId)
    );
    this.links$.next({
      ...this.links$.value,
      totalItems: this.links$.value.totalItems - 1,
    });
  }

  toggle() {
    this.isToggle$.next(!this.isToggle$.value);
  }

  onPageIndexChanged(pageIndex: number) {
    this.links$.next({ ...this.links$.value, pageIndex });
    this.getNewLeads();
  }

  showMessage(leadId?: number) {
    this.service.getLeadsHtmlNotes(leadId ?? -1).subscribe((resp) => {
      if (resp && resp.code === 'SUCCESS') {
        this.overlayService.open(HtmlMessagesComponent, {
          title: 'Message',
          width: '60vw',
          height: '60vh',
          messagesWizardData: resp.data,
        });
      }
    });
  }
}
