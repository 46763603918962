import { Injectable } from '@angular/core';
import {
  ApiService,
  IApiResult,
  IPaginatedApiResult,
} from '@erm/web/shared/data-access';
import { Observable } from 'rxjs';
import { AssignEmployeeCommand } from '../commands';
import { NewLeads } from '../models';

@Injectable({
  providedIn: 'root',
})
export class NewLeadsService {
  constructor(private apiService: ApiService) {}

  getNewLeads(
    pageIndex: number,
    pageSize: number
  ): Observable<IPaginatedApiResult<NewLeads>> {
    return this.apiService.getFromPublicApi<IPaginatedApiResult<NewLeads>>(
      `/leads/new-leads?pageIndex=${pageIndex}&pageSize=${pageSize}`
    );
  }

  checkAllNewLeads(): Observable<IApiResult<boolean>> {
    return this.apiService.putToPublicApi<IApiResult<boolean>>(
      '/leads/new-leads/view'
    );
  }

  getLeadsHtmlNotes(leadId: number): Observable<IApiResult<any>> {
    return this.apiService.getFromPublicApi<IApiResult<any>>(
      `/leads/${leadId}/html-notes`
    );
  }

  assignEmployee(
    optionAssign: AssignEmployeeCommand,
    leadId: number
  ): Observable<IApiResult<boolean>> {
    return this.apiService.putToPublicApi<IApiResult<boolean>>(
      `/leads/${leadId}/assign`,
      optionAssign
    );
  }
}
