import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private isLoading = new BehaviorSubject(false);
  public progressBarPercentage$ = new BehaviorSubject<number>(0);
  public isLoading$ = this.isLoading.asObservable();
  interval!: ReturnType<typeof setInterval>;
  requestTotal = 0;
  public toggleLoading(isLoading: boolean) {
    this.isLoading.next(isLoading);
    if (this.requestTotal == 0 && isLoading) {
      this.startTimer();
    }
  }
  newRequest() {
    this.requestTotal++;
  }
  finishRequest() {
    const currentValue = this.progressBarPercentage$.value;
    this.requestTotal--;
    if (this.requestTotal == 0) {
      this.progressBarPercentage$.next(100);
      clearInterval(this.interval);
    } else {
      this.progressBarPercentage$.next(
        currentValue + (100 - currentValue) / this.requestTotal
      );
    }
  }
  startTimer() {
    this.progressBarPercentage$.next(0);
    this.interval = setInterval(() => {
      const currentValue = this.progressBarPercentage$.value;
      this.progressBarPercentage$.next(currentValue + 1);
    }, 60);
  }
}
